import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const MatrimoniosPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Matrimonios" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Matrimonios"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Matrimonios"
      >
        <p>
        El fundamento legal lo encontramos en el libro I   artículo 78 del Código Civil de Guatemala.
        </p>        
        <p>
        El matrimonio es una institución social por la que un hombre y una mujer se unen legalmente,
        con ánimo de permanencia y con el fin de vivir juntos, procrear, alimentar y educar a sus hijos
        y auxiliarse entre sí.
          </p>
          <p>
          El licenciado Erick Claveria puede darle asesoría legal para realizar su matrimonio en
          Estados Unidos de América por ser Fedatario del Estado de Guatemala para posteriormente
          inscribirlo en el Registro Nacional de las Personas de la Republica de Guatemala o bien
          usted puede otorgar un Mandato para nombrar a un representante legal que pueda realizar
          este acto jurídico en Guatemala.
            </p>         
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default MatrimoniosPage

